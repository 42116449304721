<template>
  <div class="wrapper flex align-start justify-between">
    <div class="flex-sub">
      <el-form :model="proveForm"
               label-width="140px"
               :rules="rules" ref="proveForm" class="add-form">

        <el-form-item label="姓名" prop="xingming">
          <el-input v-model="proveForm.xingming" disabled placeholder="请填写姓名"></el-input>
        </el-form-item>
        <el-form-item label="身份证/通行证" prop="shenfenzhenghao">
          <el-input v-model="proveForm.shenfenzhenghao" disabled placeholder="请填写身份证/通行证"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="shoujihao">
          <el-input v-model="proveForm.shoujihao"  placeholder="请填写联系电话"></el-input>
        </el-form-item>
        <el-form-item label="通讯地址" prop="jiatingdizhi">
          <el-input v-model="proveForm.jiatingdizhi"  placeholder="请填写通讯地址"></el-input>
        </el-form-item>
        <el-form-item label="入职日期" prop="ruzhiriqi">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="proveForm.ruzhiriqi"
                          type="date"
                          placeholder="请选择入职日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="离职日期" prop="lizhiriqi">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="proveForm.lizhiriqi"
                          type="date"
                          placeholder="请选择离职日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="签订日期" prop="resignSignDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="proveForm.resignSignDate"
                          type="date"
                          placeholder="请选择签订日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="0" class="flex justify-around">
          <el-button class="submit-btn" type="primary" @click="submitForm('proveForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-sub  preview padding-lr">
      <div class="preview-info">
        <div class="preview-tips" v-if="!sys_offer_file">保存后可预览</div>
        <iframe v-else ref="iframe" frameborder="0" style="width: 100%;height: calc(100vh - 335px);"></iframe>
      </div>
      <div class="flex align-center justify-around margin-top">
        <el-button class="submit-btn margin-right" type="primary" @click="downOffer">下载离职证明
        </el-button>
        <el-upload
            ref="upBtn"
            class="upload-demo margin-right"
            :action="actions"
            :data="{token}"
            :on-success="handleSuccess"
            :limit="1"
            :show-file-list="false"
        >
          <el-button class="submit-btn" type="warning">上传离职证明反馈</el-button>
        </el-upload>

      </div>
      <div class="flex align-center justify-center margin-top" v-if="upload_file">
        <div>反馈附件：</div>
        <el-link v-if="upload_file" :href="upload_file" target="_blank" type="primary">
          下载离职证明反馈附件
        </el-link>
      </div>
    </div>

  </div>
</template>

<script>

import rules from "@/utils/rules";
import {QAHOST, upFile} from "@/config";
import {
  departStaffFullTimeProve,
  departStaffFullTimeProveReply,
  departStaffFullTimeProveShow,
  departStaffFullTimeShow
} from "@/api/resignation";
import {getBaseDataByOfferId, getOfferHtml} from "@/api/common";
import {getPageName, zhDate} from "@/utils";
import {downLoadOfferWord} from "@/api/entry";

export default {
  name: "offerList",
  data() {
    return {
      id: '',
      proveForm: {
        xingming: '',
        shenfenzhenghao: '',
        shoujihao: '',
        jiatingdizhi: '',
        ruzhiriqi: '',
        lizhiriqi: '',
        resignSignDate: '',

      },
      rules: {
        xingming: rules.mustInput,
        shenfenzhenghao:rules.mustInput,
        shoujihao: rules.mustInput,
        jiatingdizhi: rules.mustInput,
        ruzhiriqi: rules.mustDate,
        lizhiriqi: rules.mustDate,
        resignSignDate: rules.mustDate,
      },
      isSubmit: true,
      sys_offer_file: '',
      upload_file: '',
      iframe: '',
    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    actions() {
      return upFile
    }
  },
  created() {
    this.$emit("setIndex", '/admin/resignationList', "全职员工离职证明", true);
    this.id = Number(this.$route.params.id)
    this.departStaffFullTimeProveShow()
  },
  components: {},
  methods: {
    async departStaffFullTimeProveShow() {
      const res = await departStaffFullTimeProveShow(this.id)
      if (res.data) {
        this.proveForm = {
          xingming: res.data.xingming,
          shenfenzhenghao: res.data.shenfenzhenghao,
          shoujihao: res.data.shoujihao,
          jiatingdizhi: res.data.jiatingdizhi,
          ruzhiriqi: new Date(res.data.ruzhiriqi),
          lizhiriqi: new Date(res.data.lizhiriqi),
          resignSignDate: new Date(res.data.resignSignDate),
        }
        this.sys_offer_file = res.data.sys_offer_file
        this.upload_file = res.data.upload_file ? QAHOST + '/' + res.data.upload_file : ''
        if (res.data.sys_offer_file) {
          const iframe = await getOfferHtml(getPageName(res.data.sys_offer_file))
          this.iframe = QAHOST + '/' + iframe.data + '?v=' + new Date().getTime()
          this.$refs.iframe.contentWindow.location.replace(this.iframe)
        }
      } else {
        const info = await getBaseDataByOfferId(this.id)
        const info2 = await departStaffFullTimeShow(this.id)
        this.proveForm.xingming = info.data.staff.xingming
        this.proveForm.shenfenzhenghao = info.data.full_time_staff.shenfenzhenghao
        this.proveForm.shoujihao = info.data.full_time_staff.shoujihao
        this.proveForm.jiatingdizhi = info.data.full_time_staff.xianjudizhi
        this.proveForm.ruzhiriqi = new Date(info.data.full_time_staff.ruzhiriqi)
        this.proveForm.lizhiriqi =  new Date(info2.data.lizhiriqi)
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      let _state = await departStaffFullTimeProve(
        this.id,
        this.proveForm.xingming,
        this.proveForm.shenfenzhenghao,
        this.proveForm.shoujihao,
        this.proveForm.jiatingdizhi,
        zhDate(new Date(this.proveForm.ruzhiriqi)),
        zhDate(new Date(this.proveForm.lizhiriqi)),
        zhDate(new Date(this.proveForm.resignSignDate))
      )

      if (_state.status === 200) {
        this.$message.success(_state.info)
        await this.departStaffFullTimeProveShow()
      } else {
        this.$message.error(_state.info)
      }
    },
    //  下载附件
    async downOffer() {
      if (!this.iframe) return this.$message.error('请编辑保存后生成')
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let _data = await downLoadOfferWord(this.id, this.sys_offer_file)
      loading.close();
      let blob = _data.data
      var downloadElement = document.createElement('a');
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.style.display = 'none';
      downloadElement.href = href;
      downloadElement.setAttribute('download', this.proveForm.xingming + '离职证明.docx');
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    },
    handleSuccess(file) {
      if (file.status === 200) {
        this.upFile(file.data)
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['upBtn'].clearFiles()
    },
    async upFile(url) {
      await departStaffFullTimeProveReply(this.id, url)
      this.$message.success('上传成功')
      await this.departStaffFullTimeProveShow()
    },
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.preview {
  height: 100%;

  .preview-title {
    text-align: center;
    line-height: 60px;
    font-size: 22px;
  }

  .preview-info {
    width: 100%;
    height: calc(100vh - 330px);
    overflow-y: auto;
    padding: 0 30px;
    border: 1px solid #DCDFE6;
    border-radius: 10px;
  }
}
</style>
